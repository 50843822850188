import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import FeedItem from './FeedItem';
import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import { useFeedList } from './hooks/useFeedList';
import useUserMeInternal from './hooks/useUserMeInternal';
import PlusIcon from './icon/PlusIcon';
import SaveIcon from './icon/SaveIcon';
import { useKeyPress } from './KeyPressContext';
import Button from './lib/Button';
import Checkbox from './lib/Checkbox';
import ListGroup from './lib/ListGroup';
import Modal from './lib/Modal';
import Spinner from './lib/Spinner';
import { useModalManager } from './ModalManagerContext';

interface IFeedEntryAddToFeedModalProps {
  feedId: string;
  entry: api.feed.IFeedEntryReference;
  closeModal: () => void;
  isForeground: boolean;
}

const FeedEntryAddToFeedModal = (props: IFeedEntryAddToFeedModalProps) => {
  const dispatch = useDispatch();
  const { pushModal } = useModalManager();
  const accountInfo = useUserMeInternal();
  if (!accountInfo) {
    throw Error('Expected logged-in user');
  }
  const apiClient = useAuthedApiClient();
  const keyboardControlsActive = useSelector<store.IAppState, boolean>(state => state.keyboardControlsActive);
  const feedsMap = useSelector<store.IAppState, Map<string, api.feed.IFeedInfo>>(state => state.feeds);
  const { isRefreshing, userFeedIds } = useFeedList({ '.tag': 'id', id: accountInfo.user_id });
  const modalRef = React.useRef<HTMLDivElement | null>(null);

  const filterPredicate = (feed: api.feed.IFeedInfo) =>
    feed.type['.tag'] !== 'archive' &&
    feed.type['.tag'] !== 'ego' &&
    feed.type['.tag'] !== 'reports' &&
    feed.type['.tag'] !== 'visited' &&
    feed.type['.tag'] !== 'works' &&
    feed.for_viewer.perm.write;

  const userFeeds = cfe.ApiData.filter(
    cfe.ApiData.map(userFeedIds, feedId => feedsMap.get(feedId)!),
    feed => filterPredicate(feed),
  );

  const { apiDo: apiFeedEntryAdd, okToast, errToast } = useApiDo(apiClient, apiClient.feedEntryAdd);
  const { apiDo: apiFeedEntryRemove } = useApiDo(apiClient, apiClient.feedEntryRemove);

  const addToFeed = (feed: api.feed.IFeedInfo) => {
    updateLoading(draft => draft.add(feed.feed_id));
    apiFeedEntryAdd(
      { feed_id: feed.feed_id, url: props.entry.url, via: props.entry.entry_id },
      {
        onFinally: () =>
          updateLoading(draft => {
            draft.delete(feed.feed_id);
          }),
        onResult: res => {
          let entryId: string;
          if (res['.tag'] === 'existing') {
            okToast(`Already recently added to ${cfe.ApiHelpers.getFeedShortName(feed)}`);
            entryId = res.existing.entry_id;
          } else if (res['.tag'] === 'new_entry') {
            okToast(`Added to ${cfe.ApiHelpers.getFeedShortName(feed)}`);
            entryId = res.new_entry.entry_id;
            dispatch(MainActionCreators.prependFeedEntry(feed.feed_id, res.new_entry));
          }
          if (feed.type['.tag'] === 'save_for_later') {
            dispatch(
              MainActionCreators.mutateFeedEntry(
                feed.feed_id,
                props.entry.entry_id,
                entryToUpdate => (entryToUpdate.for_viewer.s4l = true),
              ),
            );
          }
          updateFeedMatches(draft => {
            draft.set(feed.feed_id, { entry_id: entryId, multi: false });
          });
        },
        onRouteErr: (err, defaultErrToast) => {
          if (err['.tag'] === 'slow_down') {
            errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
          } else if (err['.tag'] === 'url_not_ready') {
            errToast('Post is being processed');
          } else {
            defaultErrToast();
          }
        },
      },
    );
  };

  const removeFromFeed = (tgtFeed: api.feed.IFeedInfo, existingEntryId: string) => {
    apiFeedEntryRemove({ entry_id: existingEntryId });
    dispatch(MainActionCreators.removeFeedEntry(tgtFeed.feed_id, existingEntryId));
    if (tgtFeed.type['.tag'] === 'save_for_later') {
      // If the removal is from an s4l feed, we update the entry to remove the
      // s4l bit.
      dispatch(
        MainActionCreators.mutateFeedEntry(
          props.feedId,
          props.entry.entry_id,
          entryToUpdate => (entryToUpdate.for_viewer.s4l = undefined),
        ),
      );
    }
    updateFeedMatches(draft => {
      draft.delete(tgtFeed.feed_id);
    });
    okToast(`Removed from ${cfe.ApiHelpers.getFeedShortName(tgtFeed)}`);
  };

  const [loading, updateLoading] = useImmer<Set<string>>(new Set());
  const [feedMatches, updateFeedMatches] = useImmer<Map<string, api.feed.IEntryFindMatch>>(new Map());
  const { apiDo: apiFeedEntryFind } = useApiDo(apiClient, apiClient.feedEntryFind);
  React.useEffect(() => {
    if (!isRefreshing && cfe.ApiData.hasData(userFeeds)) {
      apiFeedEntryFind(
        { url: props.entry.url, feed_ids_to_search: userFeeds.data.map(feed => feed.feed_id) },
        {
          onResult: res => {
            updateFeedMatches(new Map(Object.entries(res.matches)));
          },
        },
      );
    }
  }, [isRefreshing]);

  const [kbCursorIndex, setKbCursorIndex] = useState(0);

  useKeyPress(
    'n',
    () => {
      // n: Select next feed.
      if (cfe.ApiData.hasData(userFeeds)) {
        setKbCursorIndex((kbCursorIndex + 1) % userFeeds.data.length);
      }
    },
    !cfe.ApiData.hasData(userFeeds) || !props.isForeground,
    10,
  );

  useKeyPress(
    'p',
    () => {
      // p: Select prev feed.
      if (cfe.ApiData.hasData(userFeeds)) {
        setKbCursorIndex((kbCursorIndex - 1 + userFeeds.data.length) % userFeeds.data.length);
      }
    },
    !cfe.ApiData.hasData(userFeeds) || !props.isForeground,
    10,
  );

  useKeyPress(
    ['o', 'Enter'],
    () => {
      // o/enter: Submit
      if (cfe.ApiData.hasData(userFeeds) && kbCursorIndex >= 0 && kbCursorIndex < userFeeds.data.length) {
        const existingEntryMatch = feedMatches.get(userFeeds.data[kbCursorIndex].feed_id);
        if (existingEntryMatch) {
          removeFromFeed(userFeeds.data[kbCursorIndex], existingEntryMatch.entry_id);
        } else {
          addToFeed(userFeeds.data[kbCursorIndex]);
        }
      }
    },
    !cfe.ApiData.hasData(userFeeds),
    10,
  );

  useKeyPress(
    'O',
    () => {
      // O: Go to "add with note"
      if (cfe.ApiData.hasData(userFeeds) && kbCursorIndex >= 0 && kbCursorIndex < userFeeds.data.length) {
        const existingEntryMatch = feedMatches.get(userFeeds.data[kbCursorIndex].feed_id);
        if (!existingEntryMatch) {
          pushModal({ kind: 'add', feed: userFeeds.data[kbCursorIndex], src: props.entry });
        }
      }
    },
    !cfe.ApiData.hasData(userFeeds),
    10,
  );

  useKeyPress(
    ['u', 'Escape', '3'],
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );
  const title = cfe.ApiHelpers.getEntryTitle(props.entry);
  return (
    <Modal.Container ref={modalRef} show={props.isForeground} close={() => props.closeModal()}>
      <Modal.Header>
        <Modal.Heading1>
          <SaveIcon size="1.3rem" offsetUp /> Save to Library
        </Modal.Heading1>
        {title ? <div className="tw-text-muted">{title}</div> : null}
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {cfe.ApiData.hasData(userFeeds)
            ? userFeeds.data.map((feed, index) => {
                const existingEntryMatch = feedMatches.get(feed.feed_id);
                return (
                  <FeedItem
                    key={feed.feed_id}
                    feed={feed}
                    onClickOverride={() => {
                      if (existingEntryMatch) {
                        // FIXME: What to do about multi?
                        removeFromFeed(feed, existingEntryMatch.entry_id);
                      } else {
                        addToFeed(feed);
                      }
                    }}
                    kbActive={keyboardControlsActive && index === kbCursorIndex}
                    prefixEle={
                      loading.has(feed.feed_id) ? (
                        <div className="tw-relative tw-w-7">
                          <div
                            // Use absolute positioning to avoid vertical expansion
                            // of FeedItem due to Spinner.
                            className="tw-absolute tw-transform -tw-translate-y-1/2 tw-w-5 tw-text-center"
                          >
                            <Spinner sm />
                          </div>
                        </div>
                      ) : (
                        <Checkbox checked={feedMatches.get(feed.feed_id) !== undefined} />
                      )
                    }
                    rightEle={
                      !existingEntryMatch && !loading.has(feed.feed_id) ? (
                        <Button
                          sm
                          variant="secondary"
                          className="!tw-min-h-[1.5rem] tw-gap-x-1"
                          title="With Note"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            pushModal({ kind: 'add', feed, src: props.entry });
                          }}
                        >
                          <PlusIcon size="0.7rem" offsetUp />
                          <span className="tw-text-xs">Note</span>
                        </Button>
                      ) : undefined
                    }
                    hideBlurb
                    hideFollowBtn
                    hideFollowers
                    hidePublisher
                    enableWindowScroll
                    scrollTarget={modalRef.current ?? undefined}
                  />
                );
              })
            : null}
          <Spinner.Presence>
            {isRefreshing || cfe.ApiData.isLoading(userFeeds) ? (
              <div className="tw-flex tw-justify-center">
                <Spinner />
              </div>
            ) : null}
          </Spinner.Presence>
        </ListGroup>
      </Modal.Body>
    </Modal.Container>
  );
};

export default FeedEntryAddToFeedModal;
