"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteSignature = void 0;
var RouteSignature;
(function (RouteSignature) {
    RouteSignature.accountAgentGetPerms = "DGQRbf4xgB#pNP?=~DXI";
    RouteSignature.accountAgentGrant = "Evc0<UWw)L-u&DXC>g*7";
    RouteSignature.accountAgentRevoke = "Evc0<UWw)L-u&DXC>g*7";
    RouteSignature.accountBeACreator = "EZ0-#*8&%`mU2}a5_u9K";
    RouteSignature.accountChangeUsername = "kY2*KH~14jg=Y?|8G=+Q";
    RouteSignature.accountCommunicationPreferenceGet = "!#0t4$}?LmR@meSIw^f>";
    RouteSignature.accountCommunicationPreferenceSet = "xtIsL(;6&YH<$KU&_<us";
    RouteSignature.accountDeregisterPushNotifDeviceToken = "2A(ftmR^D43Z!<2J*su8";
    RouteSignature.accountExplodingTokenForWebSession = "pW1kDe^RKD7q>m)i-5?y";
    RouteSignature.accountForgotPassword = "UA4bEx|_(-gBjiu=$v&|";
    RouteSignature.accountGetChromeExtensionInfo = "B3)<*<<i1bs^gI6Rtiw-";
    RouteSignature.accountNotificationDevicePolicyGet = "643^7q{l#9fB<%6uob+?";
    RouteSignature.accountNotificationDevicePolicySet = "V#fbQMeHsU5zYdwj$Wl@";
    RouteSignature.accountOnboardingDone = "@*@%F<Jvxc;<Fjx`~QJ-";
    RouteSignature.accountRegisterByEmail = "HIF}werV$B^2WpCg3@it";
    RouteSignature.accountRegisterPushNotifDeviceToken = "dnAW6H|q8Zs~xec*7ctV";
    RouteSignature.accountResetPassword = "*BrW^^B(c8)rOckYn1Df";
    RouteSignature.accountRestrict = "uoXvP0Im#&6j2x;od%s`";
    RouteSignature.accountSeppuku = "B_nN)@LL$Ku@+r106cwT";
    RouteSignature.accountTokenFromAppleSignIn = "Gk7uUKxnDopXAt%g(){y";
    RouteSignature.accountTokenFromGoogleSignin = "K_KUuW`}_IC*(i}IlZ}X";
    RouteSignature.accountTokenFromLogin = "uNopCTz-ZhDMkvimjSv4";
    RouteSignature.accountTokenFromWebSession = "EbU2w+OGVLTC}&=_NwAl";
    RouteSignature.accountVerifyEmail = "!ev1eo9A_WiT+yGN7qj>";
    RouteSignature.accountWebSessionAssume = "h4E4N{+j8+Os6xP$M38C";
    RouteSignature.accountWebSessionLogout = "*R@I9Wt!e23!N)PRpF7z";
    RouteSignature.accountWebSessionRevert = "*R@I9Wt!e23!N)PRpF7z";
    RouteSignature.accountWebSessionFromGoogleSignin = "V!++^dVlzL_0JvvH9#a~";
    RouteSignature.accountWebSessionFromLogin = "H$srW>EDun#FVYbGT4_=";
    RouteSignature.billingStripeCreateCheckoutSession = "3Foo3GdNWP54%ENQ9L=R";
    RouteSignature.billingSubscriptionEnd = "d)HP&_QqDONCuEM63KS@";
    RouteSignature.billingSubscriptionGetStatus = "<(w;!3JZV4oXEC)FcDyY";
    RouteSignature.billingSubscriptionGetStatus2 = "ZhHv9-8XBUS<;A`N@t&4";
    RouteSignature.billingTippingPeriodStatementAll = ";Jm_xDx}on9k4{hHS~tZ";
    RouteSignature.billingTippingPeriodStatementCurrent = "k!?lvA~A+qv5Ne;+fPuJ";
    RouteSignature.creatorCreate = ";2Eg)ANmn_FVMZ&Qp;GJ";
    RouteSignature.creatorDomainAttributionAdd = "TgA)tj8GnDz+n3NhywFD";
    RouteSignature.creatorDomainAttributionAll = "A?5f%_n??uWsR0$W&fq|";
    RouteSignature.creatorGet = "i>6E!lG8f)qzmyHW*idT";
    RouteSignature.creatorPairUserAndCreator = "z(1vHcL@2umUlw=eMq46";
    RouteSignature.creatorSearch = "WP*dL*mvVm3ydFty&rDb";
    RouteSignature.creatorTagAdd = "+I%3J9)3OseKC@eUon!5";
    RouteSignature.creatorTagRemove = "+I%3J9)3OseKC@eUon!5";
    RouteSignature.creatorUrlPrefixAttributionAdd = "(prJN;X$!T?`>9uG~xXI";
    RouteSignature.creatorUrlPrefixAttributionAll = "=G3}4+|9N;8(FEAH*)x?";
    RouteSignature.creatorYoutubeChannelAttributionAdd = "(ymwbYcaRqUc3^uX)M%e";
    RouteSignature.creatorYoutubeChannelAttributionAll = "`A-(axk-+POrEPDVVmZq";
    RouteSignature.creatorAccountAcceptTos = "H%&HRylRk7E@_ye%f8~n";
    RouteSignature.creatorAccountAddWorkToWorksFeed = "ekj+26B#=WepjW%6o-2=";
    RouteSignature.creatorAccountCreatorOfAll = "UP_ry;6HV|*W939aTp=y";
    RouteSignature.creatorAccountCreatorOfGet = "6OY;FA%ULn#{r4Hz;ok#";
    RouteSignature.creatorAccountDemoteFromPrimaryWorks = "I}14;0G-VlXL^s(`+<Bu";
    RouteSignature.creatorAccountGetRevenueForDay = "><Z8b^lx9uAT{Og1Y3aN";
    RouteSignature.creatorAccountGetRevenueForPastWeek = "2BLO(ovA>e%7h{u7fPW9";
    RouteSignature.creatorAccountGetStatus = "=)1;lukruUtUJ5M)F9)k";
    RouteSignature.creatorAccountGetTos = "z@$&Csv)*`PjBMn(TX>v";
    RouteSignature.creatorAccountPromoteToPrimaryWorks = "sb!2$K7b`ytKIpxq3h)Z";
    RouteSignature.creatorAccountRemoveWorkFromWorksFeed = "I}14;0G-VlXL^s(`+<Bu";
    RouteSignature.feedChangePos = "RvXiS-?q27I=6%zHeA8~";
    RouteSignature.feedCopyHashtag = "UWehocpLp5lFzBfhfOm;";
    RouteSignature.feedCreate = "$|8Saw?E~y9oCvaMCOc7";
    RouteSignature.feedCreateHashtag = "5|!dte*3&}S;&gK*V%S!";
    RouteSignature.feedCreateStream = "1SuUbO#@>1>)(Uc<(sU1";
    RouteSignature.feedDefaultEphemeralityGet = "k|Az%xPau5xN8r$2yk{Q";
    RouteSignature.feedDefaultEphemeralitySet = "$aZ-nJ!VtAKt~$zx;$8-";
    RouteSignature.feedDelete = "2Pxn+(RrkE&>FGV+iBfg";
    RouteSignature.feedEntryAdd = "=4efX`JquIH9=}M%;oOS";
    RouteSignature.feedEntryAddEgo = "ETat8AI&mauoR+t-2>#1";
    RouteSignature.feedEntryAddPost = "<(b@lAqWsJi3k8$!}t#e";
    RouteSignature.feedEntryAddReport = "j?EzNs9C+ITrHGZIx3Ls";
    RouteSignature.feedEntryAddVideo = ")1M)_Yq$JP0benI`p#1c";
    RouteSignature.feedEntryArchive = "WI7CQk7rUiCz>d1#;jV(";
    RouteSignature.feedEntryArchive2 = "nu(cTG=WDzaNwy{`eyKh";
    RouteSignature.feedEntryContentGet = "Cb~tkz?fzFG}uoq>zVeV";
    RouteSignature.feedEntryContentRemove = "7;VvPRhz!ZTtUn4;KCoP";
    RouteSignature.feedEntryContentSet = "Nq87@LCBLun};J$8D04Y";
    RouteSignature.feedEntryContentSetStatus = "$|b_^UM&4HcF`chiqRk6";
    RouteSignature.feedEntryCpcUpdate = "-E(j0(}a+bg?f-22=HPC";
    RouteSignature.feedEntryFeedbackEffects = "N8B{Ok4rM=Lx>XJvtPhh";
    RouteSignature.feedEntryFind = "W&7v48)D4GCjdEyXeJF~";
    RouteSignature.feedEntryGet = "#F22H@E{T^f)kK{ze=<~";
    RouteSignature.feedEntryGetAgentInfo = "xK?E(NGs}%kucjIOR6$;";
    RouteSignature.feedEntryGetCreators = "t2dVh2+P1$pi^0j@L!`2";
    RouteSignature.feedEntryGetOutline = "D2eUs$9V%1Jf-m|u$P}0";
    RouteSignature.feedEntryGetUrlForSharing = "B91_wYiDW;J1XbkeyepZ";
    RouteSignature.feedEntryHashtagAdd = "hf{$KWP9xlO5FI^cD^Xn";
    RouteSignature.feedEntryHashtagRemove = "Q+zUG+paK4J*qdAGaIa~";
    RouteSignature.feedEntryHashtagSetNatural = "QlTot#ZEGi60_XlK8OHw";
    RouteSignature.feedEntryHashtagUnsetNatural = "W|Kh18s-$J$O8k7fd0Cq";
    RouteSignature.feedEntryIter = "{f)CbWgpOyWq2{J@%yg_";
    RouteSignature.feedEntryIterNext = "NzauldZ#+C7sL%s02Jsx";
    RouteSignature.feedEntryLessLikeThis = "%bCqPi#@Quo*cimPg%VR";
    RouteSignature.feedEntryMarkProgress = "o?#R5moRL0rlj64F*F``";
    RouteSignature.feedEntryMoreLikeThis = "%bCqPi#@Quo*cimPg%VR";
    RouteSignature.feedEntryPrune = "s!sefL>9>EhW4X=e;%~S";
    RouteSignature.feedEntryRefresh = "|8`n*`vxizG9>dp$TY#`";
    RouteSignature.feedEntryRemove = "I^I4xe%gez|DGIwl!Dul";
    RouteSignature.feedEntryRemoveVia = "7;VvPRhz!ZTtUn4;KCoP";
    RouteSignature.feedEntryReply = "ksUs0bIEmMI++WF=)TEw";
    RouteSignature.feedEntryReqExtractMetadata = "e`TH}pV<9HG!~Axd5YoM";
    RouteSignature.feedEntrySeen = "zWV0wM)qD%2;kp~&~UJN";
    RouteSignature.feedEntrySetAgentInfo = "=4pPlYEzzvrmp29^<p@e";
    RouteSignature.feedEntrySetStaffOverrides = "NM9mmpo!-@R*-1(!-?P=";
    RouteSignature.feedEntryUnarchive = "|8`n*`vxizG9>dp$TY#`";
    RouteSignature.feedEntryUpdateBlabber = "ma2%joDMxM4}$YnS*sf|";
    RouteSignature.feedEntryUpdatePost = "QzmR2sEbUlp%uNS0)_+1";
    RouteSignature.feedFollow = "EF<I9MZ9KX4R$Yt+FJQi";
    RouteSignature.feedFollowRss = "97Zdv{knWrIDvM)B~__P";
    RouteSignature.feedFollowRssBatch = "6^>zEWL0F*cEJZG_n6MM";
    RouteSignature.feedForwardCreate = "?}P`Du`pp|=14f=12mAA";
    RouteSignature.feedForwardDelete = "wge0olfZElvfi<#efFvz";
    RouteSignature.feedForwardListDownstream = "d1!FCr!}4-w%Up6oDaPr";
    RouteSignature.feedForwardListUpstream = "d1!FCr!}4-w%Up6oDaPr";
    RouteSignature.feedGet = "n@$&23jkstD+q45K(8t=";
    RouteSignature.feedGetAcl = "Fgv1*wn_>6Z=1MP?Pe<0";
    RouteSignature.feedGetAgentTopicTaxonomy = "Rpd7xRlst`(aS)GdU5Li";
    RouteSignature.feedGetUpstreamRss = "fkZzZry%ivUCcjcwA1%-";
    RouteSignature.feedGroupAddEmbed = "GNU-YinAZN{N041`e_^N";
    RouteSignature.feedGroupAddFeed = "7xrm@@e@E9Z{yt71dOk9";
    RouteSignature.feedGroupAddSubGroup = "XcopMF`%GxG03ac8(?e5";
    RouteSignature.feedGroupCreate = "<%&D+%?$YW2mUS@nk{%^";
    RouteSignature.feedGroupGet = "$W)H&ru_|)WAx8TGU|Kj";
    RouteSignature.feedGroupGetAppConfig = "#XQ8r2Ajuw68Aa-O;Nif";
    RouteSignature.feedGroupRemoveEmbed = "2`hP3lD-Hujf@-x_#-xi";
    RouteSignature.feedGroupRemoveFeed = "2`hP3lD-Hujf@-x_#-xi";
    RouteSignature.feedGroupRemoveSubGroup = "0+;GIXY|C}p|;A>cVNY1";
    RouteSignature.feedGroupSearch = "Fo1}S`0JH&g`70K0|3|j";
    RouteSignature.feedIter = "LWbN3dPguj_P3zNt7y<i";
    RouteSignature.feedIterNext = "h;}g;voX9C>x_VOKI?Y|";
    RouteSignature.feedMuteGet = "ne_*~<VYy~n~uY>@#;Sr";
    RouteSignature.feedMuteSet = "Q@o<(#A!>532#!S0Df#E";
    RouteSignature.feedNotificationPolicyGet = "=P(y)m8L@4GU-2935ois";
    RouteSignature.feedNotificationPolicySet = ">&3MGp=E5Jg-HOsZ<k8L";
    RouteSignature.feedOnboardingTopics = "i#ZpcZSa8`w%g4Z0aH_7";
    RouteSignature.feedPopular = "ztzRoUc(kcyKrTA@MZ6X";
    RouteSignature.feedPopular2 = "m0VHG0;jx%1GYv?ym+hS";
    RouteSignature.feedPopular3 = "ocWV{11?&=;o19ZP2lRz";
    RouteSignature.feedRename = "U+sF8&cUvTt|Uo}WJ1CK";
    RouteSignature.feedSetAcl = "%p<|X|6FiS{;Y=?gqJQC";
    RouteSignature.feedSetDisplayMode = "A!N8xP+9KmBlg~2gj&`&";
    RouteSignature.feedSetImage = "xx4zU`TLV+?*mu-D1CB^";
    RouteSignature.feedSetPrimaryDomain = "`D<1zaOu-c)I_=<*@!*g";
    RouteSignature.feedSetPublisher = "!+q%nj#hOX<KKCxMPrvy";
    RouteSignature.feedSetStyle = "MUHi|fV`Pr^Rb%{gw-YZ";
    RouteSignature.feedUnfollow = "71!~lWXP@}3F8iNsq<WV";
    RouteSignature.feedUpdateBlurb = "SO7FkaPct`i)B`;hFPO)";
    RouteSignature.feedUpdateDefaultOrdering = ")}Gh}_LZU#=54L+QI8@D";
    RouteSignature.feedUpdatePermPublicRead = "*kh=5U~KNhtTRn0L{hG`";
    RouteSignature.inboxCreateSharedFeed = "Na$kd|1?c}asoXKTHRs~";
    RouteSignature.inboxGetShareSuggestions = "k`lQnOR$m8|Cx-l@(})i";
    RouteSignature.inboxGetUnread = "&J@ha>h)9p|DL?pNABG!";
    RouteSignature.inboxGetUnreadCount = "_0r0#HZVhnPS8tT{?hCT";
    RouteSignature.medialibAutoDepositProfileImage = "U+mFHD`+Ky891V&kus!X";
    RouteSignature.medialibUploadDeposited = "IF0gCe-OZXI)aEj8%;+6";
    RouteSignature.medialibUploadSetCpcVideoTarget = "Jc#BrjtC=E<`BOO7bk<E";
    RouteSignature.medialibUploadStart = "uV;ZOY?6b=SF0F~L+x0X";
    RouteSignature.newsfeedEntryIter = "8EQjA1c(_?SE?)k52luL";
    RouteSignature.newsfeedEntryIterNext = "179S?vgc`#x2hNlU!#|c";
    RouteSignature.newsfeedEntryIterAsUser = "VA@kqXy(rHq7h<2ac}u5";
    RouteSignature.newsfeedEntryIterDefault = "8EQjA1c(_?SE?)k52luL";
    RouteSignature.newsfeedEntryIterInbox = "8EQjA1c(_?SE?)k52luL";
    RouteSignature.newsfeedFilterGet = "6}tqSr$WcvX8SLz5@)3&";
    RouteSignature.newsfeedFilterSet = "$A-Z-SP6S6o;>UZrSH)G";
    RouteSignature.pdataGetNewsfeedTopicStats = "rb8Ia0T<<>`j7%fO3wYB";
    RouteSignature.pdataGetNewsfeedUserStats = "*#;4$`!`~ScL3%#vvNSE";
    RouteSignature.pdataGetUserNewsfeedActivity = "e?aM)Mv+aiC>agaG7s(E";
    RouteSignature.prismAudienceCreate = "EHGEN9nPDO-ZYBg3ucow";
    RouteSignature.prismAudienceGet = "P%>zy7irp@6*(|y^0}xr";
    RouteSignature.prismAudienceSet = "XV18JE$PNWJrClU=x^-i";
    RouteSignature.prismCreate = "1uCl0e@Z;`;(UgaZYv2Y";
    RouteSignature.prismGet = "r!+Q2wjV$pE8yVZwYljd";
    RouteSignature.prismOrphan = "Rfp6ItXap<TRyhPO2EV^";
    RouteSignature.prismSet = "4WG<8X5=i3$E#L@eg4AN";
    RouteSignature.rssAddSource = "3+yqafla2ja1q=FNWssI";
    RouteSignature.rssAddUrlPrefix = "nLfK3T;{&5ZWM!OIBFh)";
    RouteSignature.rssGetCpcSample = "oZY!ZxDYt8?Fs+@I}E;g";
    RouteSignature.rssGetHomepageScoringDryRun = "Hl=EP2j+-W+R^txFF8U^";
    RouteSignature.rssGetRssInfo = "KY3j;-m2eD#W<-K_!Z~3";
    RouteSignature.rssGetSource = "WOnMe&hew%0Em?}H~xsa";
    RouteSignature.rssRemoveUrlPrefix = "2L!k|E}rIFjT+rDv9ch|";
    RouteSignature.rssScan = ">LI~1is0{NA^G~;KKoSo";
    RouteSignature.rssSchedulePump = "w)2>F3_<JK$>gsgP)~V@";
    RouteSignature.rssSetCpcPolicy = "6=2Zq?qR><SZH32y`-QR";
    RouteSignature.rssSetEnable = "qR{TG%J$zqKJW6}k2t(9";
    RouteSignature.rssSetHomepageUrl = "E|~gFssMYosNZ;z(5xn{";
    RouteSignature.rssSetInjectMetadata = "n%Nz}Hkz^%`04E0wKvP3";
    RouteSignature.rssSetPeriod = "py@FbcSiIn-DIFBYPZtY";
    RouteSignature.rssSetUseCpc = "X)LsH-%zzyGn$wMCALNY";
    RouteSignature.rssSetUseHomepageForScoring = "6AZQ}L1xJCLOz6w*$*pU";
    RouteSignature.searchOmni = "4O5x$ioLuR4$NuJQ*OPq";
    RouteSignature.searchQueryAuthors = "u~<Cw1nw#kxvE^ii4F7a";
    RouteSignature.searchQueryTopics = "|A}d{Q|NEwm7FaZMKBmc";
    RouteSignature.staffOpAutoCpcForUrl = "Y@Bbpx6@jB{$CQgax;)J";
    RouteSignature.staffOpDomainPolicyGet = "28aUv%?~6F2A&%LP@RNI";
    RouteSignature.staffOpDomainPolicySet = "rze6kG)XRYjtp5~lyzsk";
    RouteSignature.staffOpGetTopicStats = "1-_4JQgsyzN|C){?)KM6";
    RouteSignature.staffOpStimulusAssignCanonical = "VU3`I627GCjx31p>o%`$";
    RouteSignature.staffOpStimulusCreditCreator = "NRQmP!M?@}ZcE`GiaNTQ";
    RouteSignature.staffOpStimulusCreditNewCreator = "$nyD7+&ScDbw_YN275wW";
    RouteSignature.staffOpStimulusCreditUser = "U4I2EI_YK=MwHJWfA|&B";
    RouteSignature.staffOpStimulusInjectMetadata = "@CaF3j(ol{Gi!o9r7QZ-";
    RouteSignature.staffOpStimulusRemoveInjectedMetadata = "%x1IH6ysC;nr;>{DJeSj";
    RouteSignature.staffOpStimulusUnassignCanonical = "O{m8-+<ZbQY0ry3qHvdm";
    RouteSignature.staffOpUserGetFeedRecs = "1k6sZ%rufQ0bD^O;P{VA";
    RouteSignature.stimulusAppVideoRetranscode = "<i7!)pTbh2{-i~KA|jHI";
    RouteSignature.stimulusDiscussionAdd = "yM-Q4i9qU2s05R*((QZw";
    RouteSignature.stimulusDiscussionHide = "yM-Q4i9qU2s05R*((QZw";
    RouteSignature.stimulusEgoIter = "W)A)e%jf>|Z;y<&c~N~)";
    RouteSignature.stimulusEgoIterNext = "fjn?(rc<IEf9+Jfx1!j1";
    RouteSignature.stimulusEphemeralitySet = "kI7^%0feFot~Yjz8Fx8&";
    RouteSignature.stimulusGet = "<i-;l|5mwDQFq$tlB!G3";
    RouteSignature.stimulusGetAppVideo = "joD68(Q}Xs$_G@x>jw3w";
    RouteSignature.stimulusGetAppVideoAgentInfo = "MrRYtv&VQOQEGrbgHhn=";
    RouteSignature.stimulusGetCommentary = ")1R{^pfYEY<wID#dbH^@";
    RouteSignature.stimulusGetMainEntryBatch = "vDW`g%*R`@{%gc)3Or@`";
    RouteSignature.stimulusGetRss = "J3r&T<7rb=$f@b8CI)DX";
    RouteSignature.stimulusGetTranscripts = "s6}X-WY>gDXldJh*qtj%";
    RouteSignature.storyAiApplyAiRecs = "b1`~r|6fey;Ih-#{vNt}";
    RouteSignature.storyAiGetRelatedWikis = "wM=Cq_MPZdRp_buD#pcv";
    RouteSignature.storyAiOutlineRemove = "I}14;0G-VlXL^s(`+<Bu";
    RouteSignature.storyAiPrompt = "nzowOZ_nt`mEiiclwD)v";
    RouteSignature.storyAiPromptCompareEntry = "<MR}-w8u$-mNiiv1pXN*";
    RouteSignature.storyAiRuleBlockWiki = "pL1Q_^nkiV-NNixdHc9*";
    RouteSignature.storyAiRuleGetDoubleCheckPrompt = "S+#{-4%24%MG(ySypk^)";
    RouteSignature.storyAiRuleSetDoubleCheckPrompt = "uO6BlmgozyqS`1|Ce%@@";
    RouteSignature.storyAiSummaryGenerate = "jj8XdchnfRhP`O?qLhzT";
    RouteSignature.storyAiSummaryGet = "WB}t{o|Nt59Uql&{SW=*";
    RouteSignature.storyAiSummaryRemove = "I}14;0G-VlXL^s(`+<Bu";
    RouteSignature.threadCommentAdd = "rqR^{?kL78uRd3z>`zkg";
    RouteSignature.threadCommentDelete = "hu|mH-v9gYT-Nn&J$+$w";
    RouteSignature.threadCommentDownvote = "iFr5Xk|s>Ka#`uxK|8rw";
    RouteSignature.threadCommentUnvote = "iFr5Xk|s>Ka#`uxK|8rw";
    RouteSignature.threadCommentUpvote = "iFr5Xk|s>Ka#`uxK|8rw";
    RouteSignature.threadGetBreadcrumb = "rCfP9QBs1b8zvsy3sAQT";
    RouteSignature.threadGetReplies = "TpvHYamyXQHE69h)C`&u";
    RouteSignature.threadGetSiblings = "i^Lf!%J{ey-t^mKiLHH@";
    RouteSignature.threadGetTree = "LD9@?&T3<nXuuh(CQH+t";
    RouteSignature.threadSeen = "zGSc<Q4tkwzq}9ypdyE`";
    RouteSignature.topicAssociate = "eSI05+6?^?hwWs!97tI1";
    RouteSignature.topicComputeRelatedToFeed = "N(Ob5?;t4pa~~17b5EJ?";
    RouteSignature.topicCreateFromWiki = "Rfo>>SBvHr8GVUg^jJl*";
    RouteSignature.topicDisassociate = "(y%O4)+XXPTx+x^<j0=U";
    RouteSignature.topicGetCotopics = "gF6H_r>(PHoWN|8Ge}r*";
    RouteSignature.topicGetRelatedToFeed = "h4C_wy!jB`36vJQfP(A>";
    RouteSignature.topicGetWikiUrl = "vfxb*LcDzNz#owF$fao)";
    RouteSignature.topicRecommend = "P@=H&l5=3RqKYHXiyQDb";
    RouteSignature.userGet = "E6N0^501r=Z=4suxgANk";
    RouteSignature.userGetRecentEmails = "Qtbg2Ad=LD-T*XJDyj3S";
    RouteSignature.userGetRecentPushNotifs = "!hkJ^Yw;p|(3(m1u7PZz";
    RouteSignature.userGetUserInfoForAgent = "pi-itGXK{Fp4)X-5-Y8B";
    RouteSignature.userGiveFeedback = ";D`sb|Cf3Cn*tsH|M5G3";
    RouteSignature.userMe = "$0UFI+vlx!wCcaAHT7?8";
    RouteSignature.userMeInternal = "$0UFI+vlx!wCcaAHT7?8";
    RouteSignature.userMeInternalWeb = "0`y`3?eg9MOOMTMBW!Xx";
    RouteSignature.userSendVerificationEmail = "0K;ttm9}nnp*QH-ih#i;";
    RouteSignature.userUpdateBio = "k#J=yp3ldI?>8xwh0j|k";
    RouteSignature.userUpdateName = "Y~&T{Dwq=kU3BKJDEs#t";
    RouteSignature.userUpdateWebsite = "kyT?y-+XD<ki|1rLOgpj";
})(RouteSignature = exports.RouteSignature || (exports.RouteSignature = {}));
