import React from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';

import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useMediaUploader, { getFileContentType, UploaderStatus } from './hooks/useMediaUploader';
import useToast from './hooks/useToast';
import Alert from './lib/Alert';
import Badge from './lib/Badge';
import Button from './lib/Button';
import FilePicker from './lib/FilePicker';
import InputLabel from './lib/InputLabel';
import Modal from './lib/Modal';
import TextInput, { TextInputFooterForOptional } from './lib/TextInput';
import VideoEmbed from './VideoEmbed';

type AppVideoUploadFormMode = { kind: 'standalone'; feedId: string } | { kind: 'cpc-video'; entryId: string };

const AppVideoPostModal = (props: { close: () => void; isForeground: boolean; formMode: AppVideoUploadFormMode }) => {
  const dispatch = useDispatch();
  const { setToast } = useToast();
  const apiClient = useAuthedApiClient();
  const uploadBoxRef = React.useRef<AppVideoUploadBoxMethods | null>(null);
  const [uploadId, setUploadId] = React.useState<string | null>(null);
  const { apiDo: apiFeedEntryAddVideo, okToast } = useApiDo(apiClient, apiClient.feedEntryAddVideo);
  const { apiDo: apiFeedEntryGet } = useApiDo(apiClient, apiClient.feedEntryGet, { abortable: true });

  const [title, setTitle] = React.useState('');
  return (
    <Modal.Container show={props.isForeground} close={props.close}>
      <Modal.Header>
        <Modal.Heading1>
          {props.formMode.kind === 'standalone' ? 'New Video' : 'Native Experience for Video'}
        </Modal.Heading1>
      </Modal.Header>
      <Modal.Body gutter>
        <div className="tw-pt-6" />
        {props.formMode.kind === 'standalone' ? (
          <>
            <Alert variant="warn" className="tw-mb-4">
              <Alert.Heading>Experimental Agent Feature</Alert.Heading>
              Adding standalone video is for testing and experiments, NOT INTENDED to be used in-product.
            </Alert>
            <div className="tw-mb-4">
              <InputLabel>Title</InputLabel>
              <TextInput onChange={e => setTitle(e.currentTarget.value)} value={title} maxLength={150} />
              <TextInputFooterForOptional value={title} maxLength={100} />
            </div>
          </>
        ) : null}
        <AppVideoUploadBox ref={uploadBoxRef} formMode={props.formMode} setUploadId={setUploadId} />
        <Button
          block
          className="tw-mb-4 tw-mt-8"
          disabled={!uploadId}
          onClick={() => {
            if (!uploadBoxRef.current) {
              return;
            }
            const uploaderStatus = uploadBoxRef.current.getUploaderStatus();
            if (uploaderStatus.kind === 'waiting') {
              setToast({ header: 'Upload a video' });
            } else if (uploaderStatus.kind === 'uploading') {
              setToast({ header: 'Please wait for upload to complete' });
            } else if (uploaderStatus.kind === 'error') {
              setToast({ header: 'Video has an error' });
            } else {
              if (props.formMode.kind === 'standalone') {
                const feedId = props.formMode.feedId;
                apiFeedEntryAddVideo(
                  {
                    feed_id: props.formMode.feedId,
                    title: title.length > 0 ? title : undefined,
                    video_media_upload_id: uploaderStatus.uploadId,
                  },
                  {
                    onResult: res => {
                      if (res['.tag'] !== 'new_entry') {
                        throw Error('Unexpected: New post not a new entry');
                      }
                      okToast('Posted');
                      dispatch(MainActionCreators.prependFeedEntry(feedId, res.new_entry));
                      props.close();
                    },
                  },
                );
              } else {
                apiFeedEntryGet(
                  {
                    entry_id: props.formMode.entryId,
                  },
                  {
                    onResult: res => {
                      dispatch(MainActionCreators.updateFeedEntry(res.feed.feed_id, res.entry));
                      props.close();
                    },
                  },
                );
              }
            }
          }}
        >
          {props.formMode.kind === 'standalone' ? 'Post' : 'Done'}
        </Button>
        <div className="tw-pt-6" />
      </Modal.Body>
    </Modal.Container>
  );
};

export default AppVideoPostModal;

interface AppVideoUploadBoxMethods {
  getUploaderStatus: () => UploaderStatus;
}

interface AppVideoUploadBoxProps {
  formMode: AppVideoUploadFormMode;
  setUploadId: (uploadId: string) => void;
}

const AppVideoUploadBox = React.forwardRef<AppVideoUploadBoxMethods, AppVideoUploadBoxProps>((props, ref) => {
  const { setToast } = useToast();
  const { uploadMedia, uploaderStatus } = useMediaUploader(
    'video',
    props.formMode.kind === 'standalone' ? { '.tag': 'standalone_video' } : { '.tag': 'cpc_video' },
    props.setUploadId,
    props.formMode.kind === 'standalone' ? undefined : props.formMode.entryId,
  );
  const [videoFile, setVideoFile] = React.useState<[File, string] | null>(null);

  React.useImperativeHandle(ref, () => ({
    getUploaderStatus: () => uploaderStatus,
  }));

  return (
    <div className="tw-flex tw-flex-col tw-gap-y-4">
      {videoFile ? (
        <div className="tw-relative">
          <VideoEmbed
            sources={[
              {
                src: videoFile[1],
                // VideoJS has issues playing video/quicktime, so force type to
                // video/mp4 since they're compatible.
                type: 'video/mp4',
              },
            ]}
            portrait={false}
            noOverflow
            forceNoAutoPlay
          />
          {uploaderStatus.kind === 'uploading' ? (
            <MediaBadgeContainer>
              <Badge variant="info">{uploaderStatus.progress}%</Badge>
            </MediaBadgeContainer>
          ) : uploaderStatus.kind === 'done' ? (
            <MediaBadgeContainer>
              <Badge variant="success">ready</Badge>
            </MediaBadgeContainer>
          ) : uploaderStatus.kind === 'error' ? (
            <MediaBadgeContainer>
              <Badge variant="danger">error: {uploaderStatus.errMsg}</Badge>
            </MediaBadgeContainer>
          ) : null}
        </div>
      ) : (
        <FilePicker
          onDrop={file => {
            const fileType = getFileContentType(file);
            if (fileType === 'video/quicktime' || fileType === 'video/mp4') {
              setVideoFile([file, URL.createObjectURL(file)]);
              uploadMedia(file);
            } else {
              setToast({ header: 'Unsupported format', body: 'Try .mov or .mp4', icon: 'frown' });
            }
          }}
          buttonLabel="Choose Video"
          inputAccept=".mp4,.mov,video/mp4,video/quicktime"
          dropAcceptExts={['.mp4', '.mov']}
          containerClassName="tw-py-8"
          maxFileSize={[2_000_000_000, '2GB']}
        />
      )}
    </div>
  );
});

const MediaBadgeContainer = (props: { children: React.ReactNode }) => (
  <div className="tw-absolute tw-top-1 tw-left-1">{props.children}</div>
);
