import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { useEffect } from 'react';

import * as Config from '../config';

import useApiClient, { useNoAuthApiClient } from './hooks/useApiClient';
import useUserMeInternal from './hooks/useUserMeInternal';
import Alert from './lib/Alert';
import Pagelet from './Pagelet';

const InstallExtensionPage = () => {
  const accountInfo = useUserMeInternal();
  const apiClient = useApiClient();
  const noAuthApiClient = useNoAuthApiClient(true);

  const { result: chromeExtensionInfoRes } = cfe.ApiHook.useApiRead(
    noAuthApiClient,
    noAuthApiClient.accountGetChromeExtensionInfo,
    undefined,
    res => res,
  );

  useEffect(() => {
    // This is janky because this page is polling whether the extension is
    // open. Ideally, the extension would send a request to the page if it
    // happens to be open on the login page.
    function extensionPoller() {
      if (!accountInfo) {
        return;
      }
      if (!cfe.ApiData.hasData(chromeExtensionInfoRes)) {
        return;
      }
      const chromeExtensionId = chromeExtensionInfoRes.data.chrome_extension_id;
      chrome.runtime.sendMessage(chromeExtensionId, { type: 'ping', username: accountInfo.username }, response => {
        if (response === undefined || response.type !== 'request_token') {
          return;
        }
        apiClient.accountTokenFromWebSession().then(resp => {
          if (resp.kind === api.StatusCode.Ok) {
            chrome.runtime.sendMessage(
              chromeExtensionId,
              { type: 'grant_auth_token', auth_token: resp.result.token },
              _ => {
                // No-op.
              },
            );
          }
        });
      });
    }
    const intervalId = setInterval(extensionPoller, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [chromeExtensionInfoRes]);

  return (
    <Pagelet title="Get Chrome Extension" gutter>
      <Pagelet.Heading1>Get Chrome Extension</Pagelet.Heading1>
      <div className="tw-mt-4"></div>
      <p>
        The Chrome extension is an easy way to use Superego from your browser on any site. With it installed, you can
        bring up Superego on any page to <b>ego it</b>, <b>save it</b>, <b>follow the author</b>, or{' '}
        <b>read discussions</b>.
      </p>
      <Alert variant="info">
        The extension respects your privacy.{' '}
        <a href="#privacy-notice" className="tw-text-dark dark:tw-text-light tw-font-semibold">
          Read our Privacy Notice
        </a>
      </Alert>
      <Pagelet.Heading2 className="tw-mt-6">Step 1: Install Superego from the Chrome Web Store</Pagelet.Heading2>
      <p>
        <a href="https://chrome.google.com/webstore/detail/intertimes/fkdlaeecchgindhnapnndndidcacielp" target="_blank">
          Click here to go to the store.
        </a>{' '}
        Click the "Add to Chrome" button.
      </p>
      <img className="tw-max-w-full tw-shadow-lg" src={Config.globalStaticHost + 'mind/img/chrome-ext-app-store.png'} />
      <p className="tw-mt-6">You should now have a Superego icon next to the address bar.</p>
      <img className="tw-max-w-full tw-shadow-lg" src={Config.globalStaticHost + 'mind/img/chrome-ext-spot.png'} />
      <p className="tw-mt-6">If you don't see it, click the white puzzle icon to see all of your extensions.</p>
      <Pagelet.Heading2 className="tw-mt-12">Step 2: Click the icon to sign in.</Pagelet.Heading2>
      <Pagelet.Heading2 className="tw-mt-12">Step 3: Start egoing!</Pagelet.Heading2>
      <img className="tw-max-w-full tw-shadow-lg" src={Config.globalStaticHost + 'mind/img/chrome-ext-main-page.png'} />
      <a id="privacy-notice" />
      <Pagelet.Heading2 className="tw-mt-12">Privacy Notice</Pagelet.Heading2>
      <p>
        Our extension does not have access to your browsing history. It only has access to the site you're visiting when
        you open the extension. You can verify when "Adding to Chrome" that the only permission is "Communicate with
        cooperating websites". The only "cooperating website" is our own which we use for one-click sign in.
      </p>
    </Pagelet>
  );
};

export default InstallExtensionPage;
