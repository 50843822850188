import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import type { publicApiType } from 'react-horizontal-scrolling-menu';

import 'react-horizontal-scrolling-menu/dist/styles.css';

import { ContentControlHandle, useContentControlHandle } from './ContentControlContext';
import useToast from './hooks/useToast';
import Button from './lib/Button';
import Spinner from './lib/Spinner';

export const VideoOutlineLazyLoadSection = (props: {
  apiClient: api.SuperegoClient;
  entryId: string;
  containerClassName?: string;
  outlineClassName?: string;
  btnClassName?: string;
  eagerLoad?: boolean;
}) => {
  const { setToast } = useToast();
  const [show, setShow] = React.useState(props.eagerLoad ?? false);
  const { result: feedEntryGetOutlineResult } = cfe.ApiHook.useApiRead(
    props.apiClient,
    props.apiClient.feedEntryGetOutline,
    { entry_id: props.entryId },
    res => res,
    !show,
    {
      onRouteErr: err => {
        if (err['.tag'] === 'no_outline') {
          setToast({ header: 'No outline available', icon: 'frown' });
        }
      },
    },
  );
  return (
    <div className={clsx('tw-flex tw-flex-col', props.containerClassName)}>
      {show && cfe.ApiData.hasData(feedEntryGetOutlineResult) ? (
        <VideoOutline chapters={feedEntryGetOutlineResult.data.chapters} className={props.outlineClassName} />
      ) : cfe.ApiData.isError(feedEntryGetOutlineResult) &&
        feedEntryGetOutlineResult.error?.['.tag'] === 'no_outline' ? (
        <div className={clsx('tw-italic tw-text-sm', props.btnClassName)}>No outline available</div>
      ) : (
        <Button sm variant="secondary" className={props.btnClassName} onClick={() => setShow(true)}>
          Show Outline{cfe.ApiData.isLoading(feedEntryGetOutlineResult) ? <Spinner sm className="tw-ml-2" /> : null}
        </Button>
      )}
    </div>
  );
};

const VideoOutline = (props: { chapters: api.feed.IChapterInfo[]; className?: string }) => {
  const contentControlHandle = useContentControlHandle();
  const chapterListRef = React.useRef<publicApiType | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = React.useState<number | null>(null);
  const hoverTimeoutId = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  React.useEffect(() => {
    if (!contentControlHandle || !contentControlHandle.getProgressOffset || props.chapters.length === 0) {
      return;
    }
    const intervalId = setInterval(() => {
      if (!contentControlHandle.getProgressOffset) {
        return;
      }
      const offset = contentControlHandle.getProgressOffset();
      let index = 0;
      for (const chapter of props.chapters) {
        if (chapter.start > offset) {
          break;
        }
        index += 1;
      }
      const newSelectedIndex = index === 0 ? null : index - 1;
      setSelectedIndex(newSelectedIndex);
      if (selectedIndex !== newSelectedIndex && newSelectedIndex !== null && chapterListRef.current) {
        const item = chapterListRef.current.getItemById(newSelectedIndex.toString());
        if (item) {
          chapterListRef.current.scrollToItem(item, undefined, 'center', undefined, {
            behavior: { behavior: 'smooth', scrollMode: 'if-needed' },
            duration: 250,
          });
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [contentControlHandle, props.chapters, selectedIndex]);
  if (props.chapters.length === 0) {
    return null;
  }
  return (
    <div className={props.className}>
      <div className="tw-overflow-x-scroll">
        <ScrollMenu
          // @ts-ignore
          apiRef={chapterListRef}
          wrapperClassName="ff-scrollbar"
          scrollContainerClassName="tw-flex tw-gap-x-1 tw-px-4 tw-pb-2"
        >
          {props.chapters.map((chapter, index) => (
            <Chapter
              itemId={index.toString()}
              selected={selectedIndex === index}
              chapter={chapter}
              contentControlHandle={contentControlHandle}
              select={() => setSelectedIndex(index)}
              onMouseOver={() => {
                setHoverIndex(index);
                if (hoverTimeoutId.current) {
                  clearTimeout(hoverTimeoutId.current);
                  hoverTimeoutId.current = null;
                }
              }}
              onMouseLeave={() => {
                // Delay clearing the hovered items so that if the mouse cursor
                // passes over the gap between two chapters, there is no flash
                // of the selected-chapter-summary because of the momentary
                // mouse-leave before the next mouse-over.
                hoverTimeoutId.current = setTimeout(() => {
                  setHoverIndex(null);
                  hoverTimeoutId.current = null;
                }, 200);
              }}
            />
          ))}
        </ScrollMenu>
      </div>
      {(hoverIndex !== null && hoverIndex !== selectedIndex) || selectedIndex === null ? (
        <div
          className={clsx(
            'tw-mx-5 tw-text-xs tw-p-2 tw-rounded',
            'tw-border tw-border-layout-line-light dark:tw-border-layout-line-dark',
          )}
        >
          {/* If hoverIndex is null, then selectedIndex is null, so the first
          chapter hasn't been reached. Just show the first summary. */}
          {props.chapters[hoverIndex ?? 0].summary}
        </div>
      ) : selectedIndex !== null ? (
        <div
          className={clsx(
            'tw-mx-5 tw-text-xs tw-p-2 tw-rounded',
            'tw-bg-slate-300 dark:tw-bg-slate-700',
            'tw-border tw-border-layout-line-light dark:tw-border-layout-line-dark',
          )}
        >
          {props.chapters[selectedIndex].summary}
        </div>
      ) : null}
    </div>
  );
};

const Chapter = (props: {
  itemId: string; // Required by horizontal-scrolling-menu
  selected: boolean;
  chapter: api.feed.IChapterInfo;
  contentControlHandle?: ContentControlHandle;
  select: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
}) => {
  const visibilityContext = React.useContext(VisibilityContext);
  return (
    <div
      role="button"
      className={clsx(
        'tw-h-full tw-flex tw-flex-col tw-gap-y-2 tw-justify-between tw-w-40 tw-p-2',
        'tw-rounded-lg tw-border tw-border-layout-line-light dark:tw-border-layout-line-dark',
        props.selected ? 'tw-bg-slate-300 dark:tw-bg-slate-700' : 'hover:tw-bg-highlight',
      )}
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
      onClick={() => {
        props.contentControlHandle?.seek(props.chapter.start);
        const item = visibilityContext.getItemById(props.itemId);
        if (item) {
          visibilityContext.scrollToItem(item, undefined, 'center', undefined, {
            behavior: { behavior: 'smooth' },
            duration: 250,
          });
          props.select();
        }
      }}
    >
      <div className="tw-font-bold tw-text-sm tw-line-clamp-3">{props.chapter.title}</div>
      <div className="tw-text-xs">{cfe.Formatter.formatTime(props.chapter.start)}</div>
    </div>
  );
};

export default VideoOutline;
