"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeScore = exports.getUrlPathnameForEntry = exports.getUrlPathnameForFeed = exports.getBlabber = exports.feedCmp = exports.stripUrlScheme = exports.addUrlScheme = exports.parseTikTokVideoId = exports.parseTwitterTweetId = exports.parseYouTubeVideoInfoFromUrl = exports.parseYouTubeVideoIdFromUrl = exports.mediaStartProgressToTimeOffset = exports.selectMediaStartProgress = exports.showFeedPublisher = exports.defaultShowArchive = exports.getDurationShortStringFromSecs = exports.getDurationStringFromSecs = exports.isUrlOpenable = exports.showHostname = exports.getHostname = exports.getHostnameForDisplayFromEntry = exports.getHostnameForDisplay = exports.getUrlPreviewFromEntry = exports.getViewMode = exports.getEntryPrimaryMetadata = exports.getEntryMetadata = exports.getVideoMetadataExtended = exports.getVideoMetadata = exports.getHomegrownContentInfo = exports.getEntryPostFull = exports.getEntryPost = exports.getEntryBlurb = exports.getEntryPublishDate = exports.getEntryTitleBounded = exports.getStimulusTitle = exports.getEntryTitle = exports.getEntryCanonicalUrl = exports.getEntryPrimaryUrl = exports.getShareFeedName = exports.getFeedFqNameParts = exports.getFeedShortName = void 0;
/**
 * Returns the short (un-namespaced) name of the feed.
 * NOTE: Keep in sync with version in itcs.lib.feedlib.
 */
const getFeedShortName = (feed, viewerUserId) => {
    if (feed.type['.tag'] === 'hashtag') {
        if (feed.url_name !== feed.name) {
            return feed.name;
        }
        else {
            return `#${feed.url_name}`;
        }
    }
    else if (feed.type['.tag'] === 'ego') {
        return feed.publisher.name;
    }
    else if (feed.type['.tag'] === 'visited') {
        return 'History';
    }
    else if (feed.type['.tag'] === 'save_for_later') {
        return 'Saved for later';
    }
    else if (feed.type['.tag'] === 'works') {
        // Works feed will always have publisher
        return `Works by @${feed.publisher.username}`;
    }
    else if (feed.type['.tag'] === 'share' && viewerUserId && feed.members) {
        return feed.members
            .filter(member => member.user_id !== viewerUserId)
            .map(member => member.name)
            .join(', ');
    }
    else {
        return feed.name;
    }
};
exports.getFeedShortName = getFeedShortName;
/**
 * Returns the fully-qualified name of the feed in parts:
 *  [publisher if necessary, title]
 */
const getFeedFqNameParts = (feed) => {
    if (feed.type['.tag'] === 'hashtag') {
        if (feed.url_name !== feed.name) {
            return [null, feed.name];
        }
        else {
            return [null, `#${feed.url_name}`];
        }
    }
    else if (feed.type['.tag'] === 'rss') {
        return [null, feed.name];
    }
    else if (feed.type['.tag'] === 'ego') {
        return [feed.publisher.username, feed.publisher.name];
    }
    else if (feed.type['.tag'] === 'notif') {
        return [null, feed.name];
    }
    else if (feed.type['.tag'] === 'visited') {
        return [feed.publisher.username, 'History'];
    }
    else if (feed.type['.tag'] === 'save_for_later') {
        return [feed.publisher.username, 'Saved for later'];
    }
    else if (feed.type['.tag'] === 'works') {
        // Works feed will always have publisher
        return [feed.publisher.username, `Works by @${feed.publisher.username}`];
    }
    else if (feed.publisher) {
        if (feed.publisher.username === 'rss') {
            return [null, feed.name];
        }
        else {
            return [feed.publisher.username, feed.name];
        }
    }
    else {
        return [null, feed.name];
    }
};
exports.getFeedFqNameParts = getFeedFqNameParts;
const getShareFeedName = (feed, viewerUserId) => {
    if (feed.type['.tag'] !== 'share') {
        throw Error('Unexpected feed type');
    }
    else if (!feed.members) {
        throw Error('Missing members');
    }
    return feed.members
        .filter(member => member.user_id !== viewerUserId)
        .map(member => member.name)
        .join(', ');
};
exports.getShareFeedName = getShareFeedName;
/**
 * @returns The URL to use when opening the story.
 */
function getEntryPrimaryUrl(entry) {
    var _a, _b, _c;
    if (((_a = entry.strong_ref) === null || _a === void 0 ? void 0 : _a.url_canonical) === entry.url) {
        // HACK: This is a specific hack to support reddit video links.
        return entry.url;
    }
    else {
        return (_c = (_b = entry.strong_ref) === null || _b === void 0 ? void 0 : _b.url) !== null && _c !== void 0 ? _c : entry.url;
    }
}
exports.getEntryPrimaryUrl = getEntryPrimaryUrl;
function getEntryCanonicalUrl(entry) {
    var _a, _b, _c, _d, _e;
    return (_e = (_d = (_b = (_a = entry.strong_ref) === null || _a === void 0 ? void 0 : _a.url_canonical) !== null && _b !== void 0 ? _b : (_c = entry.strong_ref) === null || _c === void 0 ? void 0 : _c.url) !== null && _d !== void 0 ? _d : entry.url_canonical) !== null && _e !== void 0 ? _e : entry.url;
}
exports.getEntryCanonicalUrl = getEntryCanonicalUrl;
/**
 * NOTE: Unlike similar functions, this does not prioritize the strong ref's
 * title as the discussion link's title is important to retain.
 *
 * Returns `null` when it's an HGC without a title. If it's non-HGC, then
 * `null` is never returned because the URL is used as a fallback title.
 */
const getEntryTitle = (entry) => {
    var _a, _b;
    const hgcInfo = getHomegrownContentInfo(entry);
    let title;
    if (entry.title) {
        title = entry.title;
    }
    else if (entry.md['.tag'] === 'ready') {
        if (hgcInfo) {
            // Because homegrown content uses ego:// urls, do not fallback to them.
            return (_a = entry.md.title) !== null && _a !== void 0 ? _a : null;
        }
        else {
            title = (_b = entry.md.title) !== null && _b !== void 0 ? _b : entry.url;
        }
    }
    else {
        title = entry.url;
    }
    return title;
};
exports.getEntryTitle = getEntryTitle;
/**
 * This is useful in agent screens to judge what the title of a URL will be
 * if it's added to a different feed. The title in the current feed may be due
 * to an override or a discussion title, which can be deceptive.
 */
const getStimulusTitle = (entry) => {
    var _a, _b;
    if (entry.strong_ref) {
        if (entry.strong_ref.md['.tag'] === 'ready') {
            return (_a = entry.strong_ref.md.title) !== null && _a !== void 0 ? _a : null;
        }
        else {
            return null;
        }
    }
    else {
        if (entry.md['.tag'] === 'ready') {
            return (_b = entry.md.title) !== null && _b !== void 0 ? _b : null;
        }
        else {
            return null;
        }
    }
};
exports.getStimulusTitle = getStimulusTitle;
/**
 * Returns title for entry bounded to 140 characters.
 */
function getEntryTitleBounded(entry) {
    const title = (0, exports.getEntryTitle)(entry);
    if (title === null) {
        return null;
    }
    if (title.length > 200) {
        return title.substring(0, 197) + '...';
    }
    else {
        return title;
    }
}
exports.getEntryTitleBounded = getEntryTitleBounded;
function getEntryPublishDate(entry) {
    var _a;
    const md = (0, exports.getEntryMetadata)(entry);
    if (md['.tag'] === 'ready') {
        return (_a = md.published_at) !== null && _a !== void 0 ? _a : null;
    }
    else {
        return null;
    }
}
exports.getEntryPublishDate = getEntryPublishDate;
function getEntryBlurb(entry) {
    const md = (0, exports.getEntryMetadata)(entry);
    if (md['.tag'] === 'ready' && md.blurb != null && md.blurb.length > 0) {
        return md.blurb;
    }
    else {
        return null;
    }
}
exports.getEntryBlurb = getEntryBlurb;
/**
 * Deprecated since it only returns the post content.
 */
function getEntryPost(entry) {
    const md = (0, exports.getEntryMetadata)(entry);
    if (md['.tag'] === 'ready' && md.content_type['.tag'] === 'post' && md.post) {
        return md.post.content;
    }
    else {
        return null;
    }
}
exports.getEntryPost = getEntryPost;
function getEntryPostFull(entry) {
    const md = (0, exports.getEntryMetadata)(entry);
    if (md['.tag'] === 'ready' && md.content_type['.tag'] === 'post' && md.post) {
        return md.post;
    }
    else {
        return null;
    }
}
exports.getEntryPostFull = getEntryPostFull;
function getHomegrownContentInfo(entry) {
    const md = (0, exports.getEntryMetadata)(entry);
    if (md['.tag'] === 'ready' && md.hgc_info) {
        return md.hgc_info;
    }
    else {
        return null;
    }
}
exports.getHomegrownContentInfo = getHomegrownContentInfo;
function getVideoMetadata(entry) {
    const md = (0, exports.getEntryMetadata)(entry);
    if (md['.tag'] === 'ready' && md.content_type['.tag'] === 'video') {
        return md.content_type;
    }
    else {
        return null;
    }
}
exports.getVideoMetadata = getVideoMetadata;
/**
 * @returns result[1] indicates whether the video is natively playable.
 *  result[2] indicates whether the video should be natively played by default.
 */
function getVideoMetadataExtended(entry) {
    var _a;
    const primaryUrl = getEntryPrimaryUrl(entry);
    const md = (0, exports.getEntryMetadata)(entry);
    const viewMode = (0, exports.getViewMode)(entry);
    if (md['.tag'] === 'ready' && md.content_type['.tag'] === 'video') {
        const streamsAvailable = !!md.content_type.dash && !!md.content_type.hls;
        return [md.content_type, streamsAvailable, !isUrlOpenable(primaryUrl) || !!((_a = viewMode === null || viewMode === void 0 ? void 0 : viewMode.cpc) === null || _a === void 0 ? void 0 : _a.default)];
    }
    else {
        return null;
    }
}
exports.getVideoMetadataExtended = getVideoMetadataExtended;
const getEntryMetadata = (entry) => {
    if (entry.strong_ref && entry.strong_ref.md['.tag'] === 'ready') {
        return entry.strong_ref.md;
    }
    else {
        return entry.md;
    }
};
exports.getEntryMetadata = getEntryMetadata;
const getEntryPrimaryMetadata = (entry) => {
    if (entry.strong_ref) {
        return entry.strong_ref.md;
    }
    else {
        return entry.md;
    }
};
exports.getEntryPrimaryMetadata = getEntryPrimaryMetadata;
const getViewMode = (entry) => {
    // While view_mode should be guaranteed to be present from the server, it may
    // be undefined because an older version of entry may be cached locally.
    const viewMode = entry.strong_ref ? entry.strong_ref.view_mode : entry.view_mode;
    return (viewMode !== null && viewMode !== void 0 ? viewMode : {
        ios_reader: false,
        js: {
            no_js: true,
        },
    });
};
exports.getViewMode = getViewMode;
// Map: hostname -> path component to keep in preview.
const extendedPreviewHostnames = new Map([
    ['github.com', 1],
    ['reddit.com', 2],
    ['twitter.com', 1],
    ['x.com', 1],
]);
/**
 * Returns a preview of a URL for display purposes.
 *
 * Typically, the preview of a URL is simply its hostname without the "www"
 * prefix. However, there's special handling for some domains.
 *
 * FIXME: If the domain is "www" (irrespective of TLD), the display name is
 * borked :) Not worth addressing for now.
 */
function getUrlPreviewFromEntry(entry) {
    let url;
    if (entry.strong_ref) {
        url = entry.strong_ref.url;
    }
    else if (entry.url_canonical) {
        url = entry.url_canonical;
    }
    else {
        url = entry.url;
    }
    if (url.startsWith('ego://')) {
        return null;
    }
    let urlObj;
    // Since URL does its own validation, and it may be stricter than server-side
    // validation, be prepared for an invalid URL error.
    try {
        urlObj = new URL(url);
    }
    catch (_a) {
        return null;
    }
    const hostname = urlObj.hostname.replace(/^(www\.)/, '');
    if (extendedPreviewHostnames.has(hostname)) {
        const partCount = extendedPreviewHostnames.get(hostname);
        const parts = urlObj.pathname.split('/').slice(1, 1 + partCount);
        if (parts.length >= partCount && parts[partCount - 1] !== '') {
            return hostname + ' › ' + parts[partCount - 1];
        }
        else {
            return hostname;
        }
    }
    else {
        return hostname;
    }
}
exports.getUrlPreviewFromEntry = getUrlPreviewFromEntry;
/**
 * Returns the hostname of a URL for display purposes.
 *
 * Since "www." is a common prefix, it's stripped.
 *
 * FIXME: If the domain is "www" (irrespective of TLD), the display name is
 * borked :) Not worth addressing for now.
 *
 */
function getHostnameForDisplay(url) {
    if (url.startsWith('ego://')) {
        return null;
    }
    // Since URL does its own validation, and it may be stricter than server-side
    // validation, be prepared for an invalid URL error.
    try {
        return new URL(url).hostname.replace(/^(www\.)/, '');
    }
    catch (_a) {
        return null;
    }
}
exports.getHostnameForDisplay = getHostnameForDisplay;
function getHostnameForDisplayFromEntry(entry) {
    if (entry.strong_ref) {
        return getHostnameForDisplay(entry.strong_ref.url);
    }
    else if (entry.url_canonical) {
        return getHostnameForDisplay(entry.url_canonical);
    }
    else {
        return getHostnameForDisplay(entry.url);
    }
}
exports.getHostnameForDisplayFromEntry = getHostnameForDisplayFromEntry;
function getHostname(url) {
    if (url.startsWith('ego://')) {
        return null;
    }
    // Since URL does its own validation, and it may be stricter than server-side
    // validation, be prepared for an invalid URL error.
    try {
        return new URL(url).hostname;
    }
    catch (_a) {
        return null;
    }
}
exports.getHostname = getHostname;
function showHostname(entry, feed) {
    if (feed.primary_domain === undefined) {
        return true;
    }
    else if (entry.strong_ref) {
        return getHostname(entry.strong_ref.url) !== feed.primary_domain;
    }
    else if (entry.url_canonical) {
        return getHostname(entry.url_canonical) !== feed.primary_domain;
    }
    else {
        return getHostname(entry.url) !== feed.primary_domain;
    }
}
exports.showHostname = showHostname;
function isUrlOpenable(url) {
    return !url.startsWith('ego://');
}
exports.isUrlOpenable = isUrlOpenable;
const getDurationStringFromSecs = (duration) => {
    return `${Math.floor(duration / 60)}:${(duration % 60).toString().padStart(2, '0')}`;
};
exports.getDurationStringFromSecs = getDurationStringFromSecs;
/**
 * Given a duration in seconds, returns a short string "10s", "2m", "1h", ...
 * composed of a single unit of time to represent the duration.
 *
 * @param duration Specify in seconds.
 * @param floorOneMinute If dealing with low-sig-fig duration, set to make "1m"
 *    the minimum time. This avoids overly specific durations (e.g. 39s) for a
 *    guesstimate. For videos where an exact duration is known, this isn't
 *    necessary.
 */
const getDurationShortStringFromSecs = (duration, floorOneMinute) => {
    if (duration < 60) {
        if (floorOneMinute) {
            return '1m';
        }
        else {
            return `${duration}s`;
        }
    }
    else if (duration > 60 * 99) {
        return `${Math.round(duration / 3600)}h`;
    }
    else {
        return `${Math.round(duration / 60)}m`;
    }
};
exports.getDurationShortStringFromSecs = getDurationShortStringFromSecs;
const defaultShowArchive = (feed) => {
    return (feed.type['.tag'] === 'archive' ||
        feed.type['.tag'] === 'ego' ||
        feed.type['.tag'] === 'notif' ||
        feed.type['.tag'] === 'reports' ||
        feed.type['.tag'] === 'visited' ||
        feed.type['.tag'] === 'works');
};
exports.defaultShowArchive = defaultShowArchive;
/**
 * This answers the following UI question: given a feed, do we show the publisher information
 * for the feed? The intent is to omit it if it's redundant or self-evident.
 *
 * NOTE: Ego feeds always return false since they should have their own special handling.
 * RSS feeds always return false because the populated publisher info is bogus to satisfy
 * older clients.
 */
function showFeedPublisher(loggedInUserId, feed) {
    if (!feed.publisher || feed.type['.tag'] === 'ego' || feed.type['.tag'] === 'rss') {
        return false;
    }
    if (feed.publisher.user_id !== loggedInUserId) {
        return true;
    }
    return (feed.type['.tag'] !== 'archive' &&
        feed.type['.tag'] !== 'favorites' &&
        feed.type['.tag'] !== 'rec_for_you' &&
        feed.type['.tag'] !== 'reports' &&
        feed.type['.tag'] !== 'save_for_later' &&
        feed.type['.tag'] !== 'visited' &&
        feed.type['.tag'] !== 'works');
}
exports.showFeedPublisher = showFeedPublisher;
/**
 * Decides whether to use the progress percentage from where the user last
 * stopped the content or to use the offset defined by the story.
 *
 * In general, we prefer the last-visit timestamp, but if that's before the
 * offset defined by the story, we use the offset instead. This case can occur
 * because of the user seeking to earlier in the video, or because the user
 * watched the video on an entry without an offset before watching the entry
 * with an offset.
 */
const selectMediaStartProgress = (lastVisit, mediaOffset, duration) => {
    let progress;
    if ((lastVisit === null || lastVisit === void 0 ? void 0 : lastVisit.progress) !== undefined && mediaOffset !== undefined && duration) {
        // Special case: Usually, we prioritize the last-visit timestamp. However,
        // if the last-visit timestamp is before the entry's offset, use the offset
        // instead.
        if (lastVisit.progress < (mediaOffset / duration) * 100) {
            progress = { kind: 'ts', ts: mediaOffset };
        }
        else {
            progress = { kind: 'perc', perc: lastVisit.progress };
        }
    }
    else {
        progress = (lastVisit === null || lastVisit === void 0 ? void 0 : lastVisit.progress)
            ? { kind: 'perc', perc: lastVisit.progress }
            : mediaOffset
                ? { kind: 'ts', ts: mediaOffset }
                : undefined;
    }
    if ((progress === null || progress === void 0 ? void 0 : progress.kind) === 'perc' && progress.perc > 99.0) {
        progress = undefined;
    }
    else if ((progress === null || progress === void 0 ? void 0 : progress.kind) === 'ts' && duration && (progress.ts / duration > 99.0 || duration - progress.ts < 10)) {
        progress = undefined;
    }
    return progress;
};
exports.selectMediaStartProgress = selectMediaStartProgress;
/**
 * Calculates the time-offset given a MediaStartProgress. Reduces boilerplate
 * code for converting a percentage-progress into a time-offset.
 */
const mediaStartProgressToTimeOffset = (startProgress, duration) => {
    if (startProgress.kind === 'ts') {
        return startProgress.ts;
    }
    else {
        if (duration) {
            return (duration * startProgress.perc) / 100;
        }
        else {
            return null;
        }
    }
};
exports.mediaStartProgressToTimeOffset = mediaStartProgressToTimeOffset;
const parseYouTubeVideoIdFromUrl = (url) => {
    const videoRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const videoRes = url.match(videoRegex);
    if (videoRes) {
        return videoRes[1];
    }
    else {
        const shortsRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/shorts\/)((\w|-){11})(?:\S+)?$/;
        const shortsRes = url.match(shortsRegex);
        if (shortsRes) {
            return shortsRes[1];
        }
        else {
            return null;
        }
    }
};
exports.parseYouTubeVideoIdFromUrl = parseYouTubeVideoIdFromUrl;
const parseYouTubeVideoInfoFromUrl = (url) => {
    const videoRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const videoRes = url.match(videoRegex);
    const offsetRegex = /[?&]t=((\d+)h)?((\d+)m)?((\d+)s?)?/;
    const offsetMatch = url.match(offsetRegex);
    let offset;
    if (offsetMatch) {
        const hours = parseInt(offsetMatch[2] || '0', 10);
        const minutes = parseInt(offsetMatch[4] || '0', 10);
        const seconds = parseInt(offsetMatch[6] || '0', 10);
        offset = hours * 3600 + minutes * 60 + seconds;
    }
    if (videoRes) {
        return { videoId: videoRes[1], offset };
    }
    else {
        const shortsRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/shorts\/)((\w|-){11})(?:\S+)?$/;
        const shortsRes = url.match(shortsRegex);
        if (shortsRes) {
            return { videoId: shortsRes[1], offset };
        }
        else {
            return null;
        }
    }
};
exports.parseYouTubeVideoInfoFromUrl = parseYouTubeVideoInfoFromUrl;
const parseTwitterTweetId = (url) => {
    const p = /^(?:https?:\/\/)?(?:twitter|x)\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/;
    const match = url.match(p);
    if (match) {
        return match[3];
    }
    else {
        return null;
    }
};
exports.parseTwitterTweetId = parseTwitterTweetId;
const parseTikTokVideoId = (url) => {
    const p = /^https?:\/\/(?:www\.)?tiktok\.com\/@(\w+)\/video\/(\d+)/;
    const match = url.match(p);
    if (match) {
        return match[2];
    }
    else {
        return null;
    }
};
exports.parseTikTokVideoId = parseTikTokVideoId;
/**
 * Returns a URL that's guaranteed to have the scheme (http / https) specified.
 *
 * If the URL is lacking a scheme or starts with `//`, we prepend `https://`.
 * This is more likely to work than not, but unfortunately, some users might
 * get burned by the assumption.
 *
 * @param url The URL to add a scheme to if unspecified.
 */
function addUrlScheme(url) {
    if (url.startsWith('//')) {
        return `https:${url}`;
    }
    else if (!url.startsWith('http://') && !url.startsWith('https://')) {
        // This is more likely to work than not, so let's do this for the ease of use.
        // Unfortunately, some users might get burned by URLs that only work via http.
        return `https://${url}`;
    }
    else {
        return url;
    }
}
exports.addUrlScheme = addUrlScheme;
function stripUrlScheme(url) {
    if (url.startsWith('http://')) {
        return url.substring(7);
    }
    else if (url.startsWith('https://')) {
        return url.substring(8);
    }
    else {
        return url;
    }
}
exports.stripUrlScheme = stripUrlScheme;
function feedCmp(a, b) {
    return (0, exports.getFeedShortName)(a).localeCompare((0, exports.getFeedShortName)(b));
}
exports.feedCmp = feedCmp;
function getBlabber(e) {
    var _a;
    if (e['.tag'] === 'ego' && e.review) {
        return e.review;
    }
    else if (e['.tag'] === 'curated' ||
        e['.tag'] === 'ego' ||
        e['.tag'] === 'favorites' ||
        e['.tag'] === 'post' ||
        e['.tag'] === 'save_for_later' ||
        e['.tag'] === 'works') {
        return (_a = e.blabber) !== null && _a !== void 0 ? _a : null;
    }
    else {
        return null;
    }
}
exports.getBlabber = getBlabber;
function getUrlPathnameForFeed(feed) {
    if (feed.type['.tag'] === 'ego') {
        return `/${feed.publisher.username}`;
    }
    else if (feed.type['.tag'] === 'hashtag') {
        return `/t/${feed.url_name}`;
    }
    else if (feed.type['.tag'] === 'rss') {
        return `/s/${feed.url_name}`;
    }
    else if (feed.type['.tag'] === 'share') {
        return `/g/${feed.feed_id}`;
    }
    else if (feed.publisher) {
        return `/${feed.publisher.username}/${feed.url_name}`;
    }
    else {
        // Not ideal, but let's be defensive here just in case there's a new feed
        // type that doesn't have a publisher for whatever reason.
        return '/404';
    }
}
exports.getUrlPathnameForFeed = getUrlPathnameForFeed;
function getUrlPathnameForEntry(entryId) {
    return `/e/${entryId}`;
}
exports.getUrlPathnameForEntry = getUrlPathnameForEntry;
function computeScore(entry) {
    var _a;
    if (!entry.for_staff) {
        return 0;
    }
    return (new Date(entry.added_at).getTime() / 1000 +
        entry.for_staff.score.stim +
        entry.for_staff.score.staff +
        entry.for_staff.score.rss +
        ((_a = entry.for_staff.score.inter) !== null && _a !== void 0 ? _a : 0));
}
exports.computeScore = computeScore;
