import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { shallowEqual } from 'react-redux';

import { useContentControlRegisterHandle } from './ContentControlContext';
import YouTubeIcon from './icon/YouTubeIcon';
import { usePrexoSlideContext } from './PrexoSlideContext';
import SectionHeaderTab from './SectionHeaderTab';
import YouTubeCustomPlayer, { StartTimeControlLabel } from './YouTubeCustomPlayer';

const YouTubeEmbed = React.memo(
  (props: {
    ytVideoId: string;
    startProgress?: cfe.ApiHelpers.MediaStartProgress;
    duration?: number;
    portrait?: boolean;
    gutterClassName?: string;
    maxHeightShort?: boolean;
    parentSlideIndex?: number;
    mainEntry?: api.feed.IFeedEntryPair;
    headerRight?: React.ReactNode;
  }) => {
    const prexoSlideContext = usePrexoSlideContext();
    const contentControlRegisterHandle = useContentControlRegisterHandle();
    return (
      <div className="tw-flex tw-flex-col tw-aligns-center">
        <SectionHeaderTab
          title="YouTube Video"
          titleSuffix={<YouTubeIcon colorOverride="white" size="30px" className="tw-ml-2" />}
          muted
          noBottomMargin
          right={
            <>
              {props.startProgress?.kind === 'ts' ? (
                <StartTimeControlLabel offset={props.startProgress.ts} />
              ) : undefined}
              {props.headerRight}
            </>
          }
        />
        <YouTubeCustomPlayer
          ytVideoId={props.ytVideoId}
          recordStoryOpen={() => null}
          startProgress={props.startProgress}
          portrait={props.portrait}
          forceNoAutoPlay
          gutterClassName={props.gutterClassName}
          focusPlay={prexoSlideContext.slideIndex === props.parentSlideIndex}
          onEnd={prexoSlideContext.nextSlide}
          loopAfterOneEnd={prexoSlideContext.slideIndex !== -1}
          maxHeightShort={props.maxHeightShort}
          mainEntry={props.mainEntry}
          registerHandle={contentControlRegisterHandle}
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.ytVideoId === nextProps.ytVideoId &&
    shallowEqual(prevProps.startProgress, nextProps.startProgress) &&
    prevProps.duration === nextProps.duration &&
    prevProps.portrait === nextProps.portrait &&
    prevProps.gutterClassName === nextProps.gutterClassName &&
    prevProps.maxHeightShort === nextProps.maxHeightShort &&
    prevProps.parentSlideIndex === nextProps.parentSlideIndex &&
    prevProps.mainEntry === nextProps.mainEntry,
);

export default YouTubeEmbed;
