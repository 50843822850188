import * as cfe from 'ego-cfe';
import React from 'react';

import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import RssIcon from './icon/RssIcon';
import Button from './lib/Button';
import InputLabel from './lib/InputLabel';
import Modal from './lib/Modal';
import TextAreaInput from './lib/TextAreaInput';
import { TextInputFooter } from './lib/TextInput';

const FollowRssFeedModal = (props: { close: () => void }) => {
  const apiClient = useAuthedApiClient();
  const { apiDo: apiFeedFollowRss, okToast } = useApiDo(apiClient, apiClient.feedFollowRss);
  const { apiDo: apiFeedFollowRssBatch } = useApiDo(apiClient, apiClient.feedFollowRssBatch);
  const [rssText, setRssText] = React.useState('');
  const [error, setError] = React.useState<string | null>(null);
  return (
    <Modal.Container show close={props.close} animate="slide-from-top">
      <Modal.Header>
        <Modal.Heading1>
          Follow RSS <RssIcon size="1.4rem" offsetUp />
        </Modal.Heading1>
      </Modal.Header>
      <Modal.Body gutter>
        <div className="tw-mt-4" />
        <InputLabel>RSS URLs (One-URL-per-line or OPML)</InputLabel>
        <TextAreaInput
          rows={rssText?.split('\n').length ?? 1}
          placeholder="URLs must begin with http[s]://..."
          onChange={e => {
            if (e.currentTarget.value.length <= 100_000) {
              setRssText(e.currentTarget.value);
            }
          }}
          value={rssText}
          maxLength={100_000}
        />
        <TextInputFooter
          value={rssText}
          maxLength={100_000}
          left={error ? <span className="tw-text-red-700">{error}</span> : undefined}
        />
        <Button
          disabled={rssText.length === 0}
          className="tw-mb-2"
          onClick={() => {
            setError(null);
            if (rssText.toLocaleLowerCase().startsWith('http') && !rssText.includes('\n')) {
              apiFeedFollowRss(
                { rss_url: rssText },
                {
                  onResult: res => {
                    okToast(`Following ${cfe.ApiHelpers.getFeedShortName(res.feed)}`);
                    props.close();
                  },
                  onRouteErr: (err, defaultErrToast) => {
                    if (err['.tag'] === 'bad_rss_url') {
                      setError('Invalid RSS');
                    } else if (err['.tag'] === 'already_following') {
                      setError('Already following RSS');
                    } else {
                      defaultErrToast();
                    }
                  },
                },
              );
            } else {
              apiFeedFollowRssBatch(
                { rss_text: rssText },
                {
                  onResult: () => {
                    okToast("We'll work on these. You'll be notified when we're done.");
                    props.close();
                  },
                },
              );
            }
          }}
        >
          Follow
        </Button>
      </Modal.Body>
    </Modal.Container>
  );
};
export default FollowRssFeedModal;
