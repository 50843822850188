import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const VideoIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    // Svg came with 640px width, but sometimes that cuts off the right border
    // so it's been increased by trial-and-error.
    viewBox={[650, 512]}
    d="M592 0H48A48 48 0 0 0 0 48v320a48 48 0 0 0 48 48h248v48H112a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16H344v-48h248a48 48 0 0 0 48-48V48a48 48 0 0 0-48-48zm0 368H48V48h544z"
  />
);

export default VideoIcon;
